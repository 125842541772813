import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BildHeader from "../../images/services/werkstatt/header.jpg"
import BildOben from "../../images/services/werkstatt/oben.jpg"
import SubNavigation from "../../components/SubNavServices"
import Termin from "../../components/Termin"

const Brillenwerkstatt: React.FC = () => {
  const siteTitle = "Brillenwerkstatt – Dienstleistungen"
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)

  return (
    <Layout title={siteTitle}>
      <SEO title={siteTitle} />
      <section className="wrapper">
        <div className="headerImage">
          <img src={BildHeader} />
        </div>
      </section>
      <Termin />
      <SubNavigation
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        activeMenuText="Brillenwerkstatt"
      />
      <section className="wrapper greyColor content">
        <h1>Brillenwerkstatt</h1>
        <h2 className="contentSubtitle">SCHWARZ Optik – Seit 1992 in Heiden</h2>
        <div className="contentColumns">
          <div className="contentColumn">
            <div className="imageCollection">
              <div className="imageRow">
                <img src={BildOben} />
              </div>
            </div>
            <p class="imageCaption">
              Zur Ausrüstung in der optischen Werkstatt zählen modernste
              CNC-Maschinen und Hochleistungsgeräte der Augenoptik.
            </p>
          </div>
          <div className="contentColumn">
            <h2>Individuelle Lösungen</h2>
            <p>
              Die von Ihnen gewählten Sehhilfen werden in der eigenen Werkstatt
              in kurzer Zeit fachlich kompetent zentriert und montiert. Bei
              SCHWARZ Optik wird sowohl bei der Fassungsberatung und
              Brillenglasabstimmung wie auch beim Anpassen von Kontaktlinsen mit
              den modernsten Mess- und Prüfgeräten gearbeitet.
            </p>

            <h2>Reparaturservice</h2>
            <p>
              Kleineren Reparaturarbeiten erledigen wir für Sie rasch und
              flexibel.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Brillenwerkstatt
