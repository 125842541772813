import React from "react"
import classnames from "classnames"
import { Link } from "gatsby"
import styled from "styled-components"
import ArrowDown from "../assets/arrow_down.svg"
import { SetStateAction, Dispatch } from "react"

const NavigationList = styled.ul`
  list-style: none;
  padding: 15px;
  margin: 0;
  display: none;

  @media (min-width: 920px) {
    display: flex;
    justify-content: space-around;
    background-color: transparent;
  }
  @media (min-width: 1240px) {
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
  }
`
const NavigationItem = styled.li`
  a {
    font-size: 21px;
    line-height: 47px;
    text-decoration: none;
    color: white !important;
    font-family: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
    letter-spacing: 0.033em;
    font-weight: 600;

    &:hover,
    &.active {
      color: #f0cc48 !important;
    }

    @media (min-width: 920px) {
      font-family: "Helvetica Neue LT W05_57 Cond", sans-serif;
      color: black !important;

      &:hover,
      &.active {
        color: black !important;
        opacity: 0.4;
      }
    }
  }
`

const StyledSubNavigation = styled.nav`
  position: fixed;
  top: 66px;
  left: 0;
  z-index: 99999;
  width: 100vw;

  &.open {
    box-shadow: 0px 3px 6px black;

    ${NavigationList} {
      display: block;
      background-color: rgba(0, 170, 155, 0.8);
      height: 100vh;
    }
  }

  @media (min-width: 920px) {
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 40px;
    padding-right: 40px;
    top: 100px;
    position: sticky;
    height: auto;
    background-color: white;
    margin: 0 auto;
    max-width: 1200px;

    &.open {
      box-shadow: none;

      ${NavigationList} {
        display: flex;
        height: auto;
        background-color: transparent;
      }
    }
  }
`

const PathIndicator = styled.div`
  padding: 12px 0 12px 15px;
  position: relative;
  font-size: 17px;
  background-color: #00aa9b;

  a {
    color: white !important;

    &:after {
      content: "|";
      padding: 0 15px;
    }
  }

  @media (min-width: 920px) {
    display: none;
  }
`

const StyledactiveMenuText = styled.span`
  font-weight: 700;
  font-family: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
  color: white;
`

const SecondLevelNavOpener = styled.div`
  position: absolute;
  height: 50px;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  content: "";
  background-size: 20px;
  background-repeat: no-repeat;
  background-image: url(${ArrowDown});
  background-position: right 25px center;
  display: inline-block;

  @media (min-width: 920px) {
    display: none;
  }
`

interface SubNavigationProps {
  isSubMenuOpen: boolean
  setSubMenuOpen: Dispatch<SetStateAction<boolean>>
  activeMenuText: string
}

const SubNavigation: React.FC<SubNavigationProps> = ({
  isSubMenuOpen,
  setSubMenuOpen,
  activeMenuText,
}) => {
  return (
    <StyledSubNavigation
      className={classnames("navGreen secondLevelNav", {
        open: isSubMenuOpen,
      })}
    >
      <PathIndicator>
        <Link to="/dienstleistungen/" title="Augenvorsorge">
          Services
        </Link>
        <StyledactiveMenuText>{activeMenuText}</StyledactiveMenuText>
      </PathIndicator>
      <NavigationList>
        <NavigationItem>
          <Link
            to="/dienstleistungen/augenvorsorge/"
            title="Augenvorsorge"
            activeClassName="active"
            partiallyActive={true}
          >
            Augenvorsorge
          </Link>
        </NavigationItem>

        <NavigationItem>
          <Link
            to="/dienstleistungen/sehtest/"
            title="Sehtest"
            activeClassName="active"
            partiallyActive={true}
          >
            Sehtest
          </Link>
        </NavigationItem>
        <NavigationItem>
          <Link
            to="/dienstleistungen/screening-test/"
            title="Screening-Test"
            activeClassName="active"
            partiallyActive={true}
          >
            Screening
          </Link>
        </NavigationItem>
        <NavigationItem>
          <Link
            to="/dienstleistungen/gewerbe-und-industrieberatung/"
            title="Gewerbe und Industrieberatung"
            activeClassName="active"
            partiallyActive={true}
          >
            Gewerbe und Industrie
          </Link>
        </NavigationItem>
        <NavigationItem>
          <Link
            to="/dienstleistungen/heimberatung/"
            title="Heimberatung"
            activeClassName="active"
            partiallyActive={true}
          >
            Heime
          </Link>
        </NavigationItem>
        <NavigationItem>
          <Link
            to="/dienstleistungen/brillenwerkstatt/"
            title="Brillenwerkstatt"
            activeClassName="active"
            partiallyActive={true}
          >
            Werkstatt
          </Link>
        </NavigationItem>
        <NavigationItem>
          <Link
            to="/dienstleistungen/services/"
            title="Services"
            activeClassName="active"
            partiallyActive={true}
          >
            Services
          </Link>
        </NavigationItem>
      </NavigationList>
      <SecondLevelNavOpener
        onClick={() => {
          setSubMenuOpen(!isSubMenuOpen)
        }}
      />
    </StyledSubNavigation>
  )
}

export default SubNavigation
