import React, { useEffect } from "react"
import styled from "styled-components"

const StyledTermin = styled.div``

const Termin: React.FC = () => {
  return (
    <StyledTermin>
      <section className="wrapper callout-link">
        <a
          href="https://www.click2date.eu/schwarz-optik.ch"
          title="Terminvereinbarung"
          target="_blank"
        >
          Terminvereinbarung – jetzt online!
        </a>
      </section>
    </StyledTermin>
  )
}

export default Termin
